<template>
  <div class="group-wizard-administrators">
    <v-layout row wrap class="pb-3">
      <v-flex xs12 class="mb-5">
        <v-layout row wrap>
          <v-flex v-if="currentAdministrator && currentAdministrator.id" xs3>
            <div class="mb-4 sw-h5">
              {{ $t("group_wizard.current_administrator") }}
            </div>
            <v-card class="border-radius">
              <v-card-text>
                <div class="sw-caption grey--text">{{ $t("common.name") }}</div>
                <div class="mb-2">
                  {{ currentAdministrator.first_name }}
                  {{ currentAdministrator.last_name }}
                </div>
                <div class="sw-caption grey--text">{{ $t("common.email") }}</div>
                <div>{{ currentAdministrator.email }}</div>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex
            v-if="currentAdministrator && currentAdministrator.id"
            xs4
          ></v-flex>
          <v-flex xs5 class="mb-5 pl-1">
            <v-card flat class="sw-primary-bg">
              <v-card-text class="text-center">
                <div class="mb-2 sw-h5">
                  <tooltip name="GroupAddAdministrator" center>
                    {{ $t("group_wizard.add_administrator") }}
                  </tooltip>
                </div>
                <div class="mb-2 sw-caption">
                  {{ $t("group_wizard.add_administrator_caption") }}
                </div>
                <v-btn fab class="sw-accent-bg" @click="dialog = true">
                  <v-icon class="sw-on-accent">add</v-icon>
                </v-btn>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12>
        <div class="mb-4 sw-h5">
          {{ $t("group_wizard.administrators") }}
        </div>
        <div
          v-if="!addedAdministrators || !addedAdministrators.length"
          class="sw-caption"
        >
          {{ $t("group_wizard.no_administrators_added") }}
        </div>
        <v-layout
          v-if="addedAdministrators && addedAdministrators.length"
          row
          wrap
          class="mx-n1"
        >
          <v-flex
            v-for="(administrator, i) in addedAdministrators"
            :key="`administrator-${i}`"
            xs3
            class="px-1"
          >
            <v-card class="border-radius fill-height">
              <v-btn
                v-if="group.wallet_user_id !== administrator.id"
                fab
                class="ma-0 sw-accent-bg sw-on-accent small-fab"
                @click="deleteGroupAdministrator(administrator.id)"
              >
                <v-icon small>close</v-icon>
              </v-btn>
              <v-card-text>
                <div class="sw-caption grey--text">Name</div>
                <div class="mb-2">
                  {{ administrator.first_name }} {{ administrator.last_name }}
                </div>
                <div class="sw-caption grey--text">Email</div>
                <div>{{ administrator.email }}</div>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-dialog v-model="dialog" width="600px" persistent>
      <v-card class="border-radius">
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon small>close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-text class="py-0 text-center sw-h4">
          {{ $t("group_wizard.add_administrator") }}
        </v-card-text>
        <v-card-text v-if="window === 0">
          <v-form data-vv-scope="form-0" @submit.prevent="findUser">
            <v-layout row wrap class="px-5">
              <v-flex xs12 class="mb-4">
                <v-text-field
                  v-model="email"
                  data-vv-name="email"
                  :data-vv-as="$tc('common.email')"
                  v-validate.disable="'required|email'"
                  :placeholder="$tc('common.email')"
                  hide-details
                ></v-text-field>
                <ErrorMessages
                  :error-messages="errors.collect('form-0.email')"
                />
              </v-flex>
              <v-flex xs12 class="text-xs-center">
                <v-btn
                  round
                  large
                  class="sw-accent-bg sw-on-accent text-none"
                  :loading="isLoading"
                  @click="findUser"
                  >{{ $t("find-user") }}</v-btn
                >
              </v-flex>
            </v-layout>
          </v-form>
        </v-card-text>
        <v-card-text v-if="window === 1">
          <v-form data-vv-scope="form-1" @submit.prevent>
            <v-layout row wrap class="px-5">
              <v-flex xs12>
                <v-text-field
                  v-model="newUser.first_name"
                  v-validate.disable="'required'"
                  data-vv-name="first_name"
                  :data-vv-as="$t('common.first_name')"
                  :placeholder="$t('common.first_name')"
                  hide-details
                ></v-text-field>
                <ErrorMessages
                  :error-messages="errors.collect('form-1.first_name')"
                />
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="newUser.last_name"
                  v-validate.disable="'required'"
                  data-vv-name="last_name"
                  :data-vv-as="$t('common.last_name')"
                  :placeholder="$t('common.last_name')"
                  hide-details
                ></v-text-field>
                <ErrorMessages
                  :error-messages="errors.collect('form-1.last_name')"
                />
              </v-flex>
              <v-flex xs12 class="mb-3">
                <v-text-field
                  v-model="email"
                  :placeholder="$t('common.email')"
                  :disabled="true"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 class="text-center">
                <v-btn
                  round
                  large
                  class="sw-accent-bg sw-on-accent text-none"
                  :loading="isLoading"
                  @click="createUser"
                  >{{ $t("common.add") }}</v-btn
                >
              </v-flex>
            </v-layout>
          </v-form>
        </v-card-text>
        <v-card-text v-if="window === 2">
          <v-layout row wrap class="px-5">
            <v-flex xs12>
              <v-text-field
                v-model="user.first_name"
                :placeholder="$t('common.first_name')"
                :disabled="true"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="user.last_name"
                :placeholder="$t('common.last_name')"
                :disabled="true"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 class="mb-3">
              <v-text-field
                v-model="email"
                :placeholder="$t('common.email')"
                :disabled="true"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 class="text-center">
              <v-btn
                v-if="!userGroupAdministrationRoles.length"
                round
                large
                class="sw-accent-bg sw-on-accent text-none"
                :loading="isLoading"
                @click="createGroupAdministrator(user.id)"
                >{{ $t("common.add") }}</v-btn
              >
              <v-btn
                v-if="userGroupAdministrationRoles.length"
                round
                large
                class="sw-accent-bg sw-on-accent text-none"
                :loading="isLoading"
                @click="updateGroupAdministrator(user.id)"
                >{{ $t("common.add") }}</v-btn
              >
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>
    <ConfirmationDialog ref="confirmationDialog" />
  </div>
</template>

<script>
export default {
  data: () => ({
    isLoading: false,
    dialog: false,
    window: 0,
    email: "",
    newUser: {},
    user: {},
    userGroupAdministrationRoles: [],
  }),
  computed: {
    group() {
      return this.$store.getters.groupWizardGroup;
    },
    currentUser() {
      return this.$store.getters.currentUser;
    },
    administrators() {
      return this.$store.getters.groupWizardAdministrators;
    },
    currentAdministrator() {
      return this.administrators.find((el) => el.id === this.currentUser.id);
    },
    addedAdministrators() {
      return this.administrators.filter(
        (el) =>
          el.id !== this.currentUser.id &&
          el.group_administration_roles.includes("app_administrator"),
      );
    },
  },
  methods: {
    async findUser() {
      const isValid = await this.$validator.validateAll("form-0");

      if (!isValid) return;

      this.isLoading = true;

      try {
        const userSearchParams = { email: this.email };

        const userSearchResponse = await this.$api.users.search(
          userSearchParams,
        );

        if (!userSearchResponse.data || !userSearchResponse.data.data[0]) {
          this.isLoading = false;
          this.window = 1;
          return;
        }

        this.user = userSearchResponse.data.data[0];

        const groupAdministratorsGetParams = [
          this.group.id,
          userSearchResponse.data.data[0].id,
        ];

        const groupAdministratorsGetResponse = await this.$api.groupAdministrators.get(
          ...groupAdministratorsGetParams,
        );

        if (
          !groupAdministratorsGetResponse.data ||
          !groupAdministratorsGetResponse.data.data.id
        ) {
          this.isLoading = false;
          return;
        }

        const groupAdministrationRoles =
          groupAdministratorsGetResponse.data.data.group_administration_roles;

        if (groupAdministrationRoles.includes("app_administrator")) {
          this.errors.add({
            msg: this.$t("group_wizard.already_administrator_error"),
            scope: "form-0",
            field: "email",
          });
        } else {
          this.userGroupAdministrationRoles = groupAdministrationRoles;
          this.window = 2;
        }

        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;

        if (error.response && error.response.status === 404) {
          this.window = 2;
        }
      }
    },
    async createUser() {
      const isValid = await this.$validator.validateAll("form-1");

      if (!isValid) return;

      this.isLoading = true;

      const specs = {
        first_name: this.newUser.first_name,
        last_name: this.newUser.last_name,
        email: this.email,
        password: Math.random().toString(36).substr(2, 8),
      };

      this.$api.users.create(specs).then(
        (response) => {
          if (!response.data || !response.data.data.id) {
            this.isLoading = false;
            return;
          }

          this.createGroupAdministrator(response.data.data.id);
        },
        () => {
          this.isLoading = false;
        },
      );
    },
    async createGroupAdministrator(id) {
      const specs = [
        this.group.id,
        {
          user_id: id,
          roles: "app_administrator",
        },
      ];

      this.$api.groupAdministrators.create(...specs).then(
        (response) => {
          this.isLoading = false;

          if (response.status !== 200) {
            return;
          }

          this.$emit("administrator-created");

          this.$store.dispatch("addNotification", {
            message: this.$t("user-attached"),
          });

          this.dialog = false;
        },
        () => {
          this.isLoading = false;
        },
      );
    },
    async updateGroupAdministrator(id) {
      const specs = [
        this.group.id,
        id,
        {
          roles: [
            ...this.userGroupAdministrationRoles,
            "app_administrator",
          ].toString(),
        },
      ];

      this.$api.groupAdministrators.update(...specs).then(
        (response) => {
          this.isLoading = false;

          if (response.status !== 200) {
            return;
          }

          this.$emit("administrator-updated");

          this.$store.dispatch("addNotification", {
            message: this.$t("user-attached"),
          });

          this.dialog = false;
        },
        () => {
          this.isLoading = false;
        },
      );
    },
    async deleteGroupAdministrator(id) {
      const isConfirmed = await this.$refs.confirmationDialog.open(
        this.$t("group_wizard.administrator_delete_warning"),
        this.$t("no"),
        this.$t("yes"),
      );

      if (!isConfirmed) return;

      this.isLoading = true;

      try {
        const administrator = this.addedAdministrators.find(
          (el) => el.id === id,
        );

        const groupAdministrationRoles = administrator.group_administration_roles.filter(
          (el) => el !== "app_administrator",
        );

        if (!groupAdministrationRoles.length) {
          const groupAdministratorsDeleteParams = [this.group.id, id];

          const groupAdministratorsDeleteResponse = await this.$api.groupAdministrators.delete(
            ...groupAdministratorsDeleteParams,
          );

          this.isLoading = false;

          if (groupAdministratorsDeleteResponse.status !== 200) {
            return;
          }
        } else {
          const groupAdministratorsUpdateSpecs = [
            this.group.id,
            id,
            {
              roles: groupAdministrationRoles.toString(),
            },
          ];

          await this.$api.groupAdministrators.update(
            ...groupAdministratorsUpdateSpecs,
          );

          this.isLoading = false;

          if (response.status !== 200) {
            return;
          }
        }
      } catch (error) {
        if (error) {
          this.isLoading = false;
          return;
        }
      }

      this.$emit("administrator-deleted");

      this.$store.dispatch("addNotification", {
        message: this.$t("group_wizard.administrator_deleted"),
      });
    },
  },
  watch: {
    dialog(val) {
      this.errors.clear();
      this.window = 0;
      this.email = "";
      this.newUser = {};
      this.user = {};
    },
  },
};
</script>

<style lang="scss" scoped>
.small-fab {
  position: absolute;
  height: 24px;
  width: 24px;
  top: -12px;
  right: -12px;
  z-index: 1;

  .v-icon {
    font-size: 12px !important;
  }
}
</style>
