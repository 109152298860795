<template>
  <div class="group-wizard-confirmation">
    <v-layout row wrap class="mb-5">
      <v-flex xs12 class="mb-4 sw-h3 sw-accent">
        {{ $t("group_wizard.confirmation_title") }}
      </v-flex>
      <v-flex xs12>
        <v-layout row wrap>
          <v-flex xs4 class="pr-5">
            <div class="mb-2 sw-h5">
              {{ $t("group_wizard.name") }}
            </div>
            <div class="mb-4 sw-caption">
              {{ $t("group_wizard.name_caption") }}
            </div>
            {{ group.name }}
          </v-flex>
          <v-flex v-if="isLandingPageEnabled" xs4 class="mb-4 pl-5">
            <div class="mb-2 sw-h5">
              {{ $t("group_wizard.subdomain_name") }}
            </div>
            <div class="mb-4 sw-caption">
              {{ $t("group_wizard.subdomain_name_caption") }}
            </div>
            <div>
             {{group.subdomain}}<span v-if="defaultDomain && defaultDomain.length > 0">.{{
                  defaultDomain
                }}</span>
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="mb-5">
      <v-flex xs12 class="mb-4 sw-h3 sw-accent">
        {{ $t("group_wizard.message_settings_title") }}
      </v-flex>
      <v-flex xs4 class="pr-5">
        <v-layout row wrap>
          <v-flex xs12 class="mb-4 sw-h5">
            {{ $tc("group_wizard.email_sender_address") }}
          </v-flex>
          <v-flex xs12>
            {{ emailSenderAddress }}
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs4 class="px-5">
        <v-layout row wrap>
          <v-flex xs12 class="mb-2 sw-h5">
            {{ $tc("group_wizard.email_sender_name") }}
          </v-flex>
          <v-flex xs12 class="mb-4 sw-caption">
            {{ $t("group_wizard.email_sender_name_caption") }}
          </v-flex>
          <v-flex xs12>
            {{ group.email_sender_name }}
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs4 class="pl-5">
        <v-layout row wrap>
          <v-flex xs12 class="mb-4 sw-h5">
            {{ $tc("group_wizard.sms_sender_name") }}
          </v-flex>
          <v-flex xs12 class="mb-4 sw-caption">
            {{ $t("group_wizard.sms_sender_name_caption") }}
          </v-flex>
          <v-flex xs12>
            {{ group.sms_sender_name }}
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="pb-3">
      <v-flex xs12 class="mb-4 sw-h3 sw-accent">
        {{ $t("group_wizard.added_administrators_title") }}
      </v-flex>
      <v-flex xs12 class="mb-4 sw-h5">
        {{ $t("group_wizard.following_administrators_invited") }}
      </v-flex>
      <v-flex
        xs1
        v-if="!administrators || !administrators.length"
        class="sw-caption"
      >
        {{ $t("group_wizard.no_administrators_invited") }}
      </v-flex>
      <v-flex v-if="administrators && administrators.length" xs12>
        <v-layout row wrap class="mx-n1">
          <v-flex
            v-for="(administrator, i) in administrators"
            :key="`administrator-${i}`"
            xs3
            class="px-1"
          >
            <v-card class="border-radius fill-height">
              <v-card-text>
                <div class="sw-caption grey--text">{{ $t("common.name") }}</div>
                <div class="mb-2">
                  {{ administrator.first_name }} {{ administrator.last_name }}
                </div>
                <div class="sw-caption grey--text">{{ $t("common.email") }}</div>
                <div>{{ administrator.email }}</div>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  computed: {
    emailSenderAddress() {
      return this.$store.getters.groupWizardGroup.email_sender_address || process.env.VUE_APP_EMAIL_SENDER_ADDRESS;
    },
    isLandingPageEnabled() {
      return process.env.VUE_APP_LANDINGPAGE_ENABLED === "true";
    },
    defaultDomain() {
      return process.env.VUE_APP_DEFAULT_DOMAIN;
    },
    group() {
      return this.$store.getters.groupWizardGroup;
    },
    administrators() {
      return this.$store.getters.groupWizardAdministrators.filter((el) =>
        el.group_administration_roles.includes("app_administrator"),
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
