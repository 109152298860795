<template>
  <div class="group-wizard-overall-settings">
    <v-form data-vv-scope="overall-settings">
      <v-layout row wrap>
        <v-flex xs6 class="pr-5">
          <v-layout row wrap>
            <v-flex xs12 class="mb-5">
              <div class="mb-2 sw-h5">
                {{ $t("group_wizard.name") }}
              </div>
              <div class="sw-caption">
                {{ $t("group_wizard.name_caption") }}
              </div>
              <v-text-field
                v-model="group.name"
                v-validate.disable="'required'"
                data-vv-name="name"
                :data-vv-as="$t('group_wizard.name')"
                :placeholder="$t('group_wizard.name_placeholder')"
                hide-details
              ></v-text-field>
              <ErrorMessages
                :error-messages="errors.collect('overall-settings.name')"
              />
            </v-flex>
            <v-flex v-if="isLandingPageEnabled" xs12 class="mb-5">
              <div class="mb-2 sw-h5">
                {{ $t("group_wizard.subdomain_name") }}
              </div>
              <div class="sw-caption">
                {{ $t("group_wizard.subdomain_name_caption") }}
              </div>
              <v-layout class="row align-center">
                <v-flex class="xs12">
                  <v-text-field
                    v-model="group.subdomain"
                    data-vv-name="subdomain"
                    :data-vv-as="$t('group_wizard.subdomain_name')"
                    :placeholder="$t('group_wizard.subdomain_name_placeholder')"
                    @input="group.subdomain = group.subdomain.toLowerCase()"
                    hide-details
                  ></v-text-field>
                  <ErrorMessages
                    :error-messages="
                      errors.collect('overall-settings.subdomain')
                    "
                  />
                </v-flex>
                <span v-if="defaultDomain && defaultDomain.length > 0"
                  >.{{ defaultDomain }}</span
                >
              </v-layout>
            </v-flex>
            <v-flex xs12 class="mb-5">
              <div class="mb-2 sw-h5">
                {{ $t("group_wizard.choose_language") }}
              </div>
              <v-select
                v-model="group.supported_languages"
                v-validate.disable="'required'"
                :data-vv-name="'supported_languages'"
                :data-vv-as="$t('groupSupportedLanguagesValidationName')"
                :items="supportedLanguages"
                :menu-props="{ closeOnContentClick: true }"
                item-text="name"
                item-value="value"
                return-object
                multiple
                :label="$t('groupSupportedLanguagesLabel')"
                @input="setGroupDefaultLanguage"
                hide-details
              >
                <template v-slot:selection="{ item, index }">
                  <span v-if="index === 0 || index === 1 || index === 2">
                    <span v-if="index !== 0" class="mr-2">,</span>
                    <span
                      :class="`mr-2 flag-icon flag-icon-${item.iso2}`"
                    ></span>
                    <span>{{ item.name }}</span>
                  </span>
                  <template v-if="index === 3">
                    <span class="ml-2 grey--text sw-caption"
                      >(+{{ group.supported_languages.length - 3 }} more)</span
                    >
                  </template>
                </template>
                <template slot="item" slot-scope="data">
                  <span
                    :class="`mr-2 flag-icon flag-icon-${data.item.iso2}`"
                  ></span>
                  <span class="slot-item">{{ data.item.name }}</span>
                  <v-spacer></v-spacer>
                </template>
              </v-select>
              <ErrorMessages
                :error-messages="
                  errors.collect('overall-settings.supported_languages')
                "
              />
              <v-select
                v-model="group.language_iso"
                v-validate.disable="'required'"
                :data-vv-name="'language_iso'"
                :data-vv-as="$t('groupDefaultLanguageValidationName')"
                :items="group.supported_languages"
                item-text="name"
                item-value="value"
                :label="$t('groupDefaultLanguageLabel')"
                return-object
                hide-details
              >
                <template slot="no-data">
                  <div class="sw-pa-1">Choose supported languages first</div>
                </template>
                <template slot="selection" slot-scope="data">
                  <span
                    :class="`mr-2 flag-icon flag-icon-${data.item.iso2}`"
                  ></span>
                  {{ data.item.name }}
                </template>
                <template slot="item" slot-scope="data">
                  <span
                    :class="`mr-2 flag-icon flag-icon-${data.item.iso2}`"
                  ></span>
                  <span class="slot-item">{{ data.item.name }}</span>
                </template>
              </v-select>
              <ErrorMessages
                :error-messages="
                  errors.collect('overall-settings.language_iso')
                "
              />
            </v-flex>
            <!-- wallet??? -->
            <v-flex v-if="isWalletEnabled" xs12>
              <div class="mb-4 sw-h5">
                {{ $t("group_wizard.payment_settings") }}
              </div>
              <v-card flat class="mb-4 sw-primary-bg">
                <v-card-text>
                  {{ $t("gw-payment-settings-text") }}
                </v-card-text>
              </v-card>
              <div :style="{ maxWidth: '450px' }">
                <v-select
                  v-model="group.currency"
                  item-text="name"
                  item-value="value"
                  :items="currencies"
                  v-validate.disable="'required'"
                  :data-vv-name="'currency'"
                  :data-vv-as="$t('gw-currency')"
                  :menu-props="{ closeOnContentClick: true }"
                  :disabled="group && group.id ? true : false"
                  :label="$t('gw-currency-label')"
                  hide-details
                >
                  <template slot="item" slot-scope="data">
                    <div class="slot-item sw-caption">
                      {{ data.item.name }}
                    </div>
                  </template>
                </v-select>
                <ErrorMessages
                  :error-messages="errors.collect('overall-settings.currency')"
                />
                <v-text-field
                  v-model="group.default_tax_rate"
                  v-validate.disable="'required|numeric|max_value:100'"
                  :data-vv-name="`default_tax_rate`"
                  :data-vv-as="$t('gw-tax-rate')"
                  :label="$t('gw-tax-rate-label')"
                  suffix="%"
                  :disabled="Boolean(group && group.id)"
                  hide-details
                ></v-text-field>
                <ErrorMessages
                  :error-messages="
                    errors.collect('overall-settings.default_tax_rate')
                  "
                />
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs6 class="pl-5">
          <v-card flat class="ml-5 sw-primary-bg">
            <v-card-text class="py-5 text-center">
              <div class="mb-2 sw-h5 sw-on-primary">
                <tooltip name="GroupImage" center>
                  {{ $t("group_wizard.group_image") }}
                </tooltip>
              </div>
              <div class="mb-3 sw-caption sw-on-primary">
                {{ $t("group_wizard.group_image_caption") }}
              </div>
              <MediaManager
                v-model="group.group_image"
                :media-type="'images'"
                label=""
              />
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-form>
  </div>
</template>

<script>
import "flag-icon-css/css/flag-icon.css";
import { languageIcons } from "@/language-icons.json";

export default {
  inject: ["parentValidator"],
  computed: {
    isWalletEnabled() {
      return process.env.VUE_APP_WALLET_ENABLED === "true";
    },
    isLandingPageEnabled() {
      return process.env.VUE_APP_LANDINGPAGE_ENABLED === "true";
    },
    defaultDomain() {
      return process.env.VUE_APP_DEFAULT_DOMAIN;
    },
    supportedLanguages() {
      return this.transformLanguages(process.env.VUE_APP_GROUP_LANGUAGES);
    },
    currencies() {
      return [
        { name: "EUR", value: "EUR" },
        { name: "DKK", value: "DKK" },
      ];
    },
    group: {
      get() {
        return this.$store.getters.groupWizardGroup;
      },
      set(v) {
        this.$store.dispatch("setGroupWizardGroup", v);
      },
    },
  },
  created() {
    this.$validator = this.parentValidator;
  },
  methods: {
    transformLanguages(value) {
      if (!value) return [];

      return value.split(",").map((el) => ({
        name: this.$t(`common.${el}_label`),
        value: el,
        iso2: languageIcons.find((i) => i.key === el).iso2,
      }));
    },
    setGroupDefaultLanguage() {
      if (
        !this.group.supported_languages ||
        !this.group.supported_languages[0]
      ) {
        return;
      }
      this.group.language_iso = this.group.supported_languages[0];
    },
  },
};
</script>

<style lang="scss" scoped></style>
