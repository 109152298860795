<template>
  <v-layout row wrap class="text-center">
    <v-flex xs12 class="mb-5">
      <div class="sw-h3 font-weight-light">
        {{ $t("group_wizard.group_created") }}
      </div>
    </v-flex>
    <v-flex xs12>
      <v-btn
        round
        class="sw-accent-bg sw-on-accent text-none"
        @click="toGetStarted"
        :loading="isLoading"
        >{{ $t("group_wizard.get_started") }}</v-btn
      >
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  data: () => ({
    isLoading: false,
  }),
  computed: {
    group() {
      return this.$store.getters.groupWizardGroup;
    },
    appLanguage() {
      return this.$store.getters.appLanguage;
    },
  },
  methods: {
    toGetStarted() {
      this.isLoading = true;

      const params = [
        this.group.id,
        {
          lang: this.appLanguage,
        },
      ];

      this.$store.dispatch("getGroupLeftMenu", params).then(
        (response) => {
          this.isLoading = false;

          if (!response.data || !response.data.data) {
            return;
          }

          this.$router.push(
            {
              name: "get_started",
            },
            () => {},
          );
        },
        () => {
          this.isLoading = false;
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
